body, html {
  background: #333;
  color: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background: #333;
  color: white;
}

.white-text {
  color:white;
}

.top-1 {
  margin-top: 1em;
}

.top-2 {
  margin-top: 2em;
}

.top-3 {
  margin-top: 3em;
}