.EncounterCalculator {

}

.tiny-input {
    width: 2.5em;
    border-radius: 5px;
}

.diff-easy {
    color: lime;
}

.diff-medium {
    color: yellow;
}

.diff-hard {
    color: darkred;
}

.diff-deadly {
    color: red;
    font-weight: bold;
}