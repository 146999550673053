.DankNav {
    color: white;
}

.navbar-brand, .navbar-brand:hover, a {
    color: white;
}

.nav-item {
    color: white;
}
